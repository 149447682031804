var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    {
      staticClass: "top-section justify-center",
      style: _vm.topImg ? "background-image: url(" + _vm.topImg + ")" : "",
    },
    [
      _vm.overlay
        ? _c(
            "v-overlay",
            { attrs: { absolute: true, value: true } },
            [_vm._t("default")],
            2
          )
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }