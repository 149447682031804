<template>
    <v-row class="top-section justify-center" v-bind:style="topImg?'background-image: url('+topImg+')':''">
        <v-overlay
            v-if="overlay"
            :absolute="true"
            :value="true"
        >
            <slot></slot>
        </v-overlay>

        <slot v-else></slot>
    </v-row>
</template>

<script>

export default {
    props: {
        topImg: {
            type: String,
            required: false,
        },
        overlay: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.top-section ::v-deep h1 {
    text-align: center;
}

.top-section {
    width: 100%;
    height: 200px;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: url('/img/bg-top.jpg') 50% 50% no-repeat;
    background-size: cover;
}

.top-section .v-btn {
    height: auto;
    text-transform: capitalize;
    font-family: 'Sansita', sans-serif;
    font-size: 250%;
    margin: auto 20px;
    padding: 10px 20px;
    color: var(--v-golden-base);
    background: var(--v-purple-base);
}

#app .top-section .v-btn.current {
    background: var(--v-secondary-base);
}

::v-deep .top-section .v-btn.current .v-btn__content {
    color: var(--v-primary-base);
}
</style>
