<template>
    <v-container>
        <page-filters-section top-img="/img/enhancement/enhancement-header.png">
            <div class="d-flex align-center justify-center flex-column">
                <h1>Wizarropedia</h1>
                <h4>Enhancements list</h4>
            </div>
        </page-filters-section>

        <div class="page-content">
            <v-card dark class="transparent">
                <v-card-title>
                    Elixirs
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    class="transparent"
                    :headers="headers"
                    :items="elixirsList"
                    :loading="0 === elixirsList.length"
                    loader-height="10"
                    :items-per-page=200
                    :hide-default-footer="true"
                    :sortable="true"
                    :search="search"
                    mobile-breakpoint="501"
                >
                    <template v-slot:item.name="{ item }">
                        <div class="d-flex align-center justify-start">
                            <img :src="item.thumbnail_82" class="table-img" :alt="item.name"/>
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                    <template v-slot:item.marketplace="{ item }">
                        <v-btn :to="{ name: 'Marketplace', params: {search: item.name}}">
                            Buy now
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>

            <br/><br/>
            <br/><br/>

            <v-card dark class="transparent">
                <v-card-title>
                    Summoning ingredients
                    <v-spacer></v-spacer>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-card-title>
                <v-data-table
                    class="transparent"
                    :headers="headers"
                    :items="ingredientsList"
                    :loading="0 === ingredientsList.length"
                    :items-per-page=200
                    :hide-default-footer="true"
                    :sortable="true"
                    :search="search"
                    mobile-breakpoint="501"
                >
                    <template v-slot:item.name="{ item }">
                        <div class="d-flex align-center justify-start">
                            <img :src="item.thumbnail_82" class="table-img" :alt="item.name"/>
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                    <template v-slot:item.marketplace="{ item }">
                        <v-btn :to="{ name: 'Marketplace', params: {search: item.name}}">
                            Buy now
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card>

        </div>
    </v-container>
</template>

<script>

import EnhancementContract from "@/services/contracts/enhancementContract";
import {apiServiceVue} from "@/services/apiService.vue";
import PageFiltersSection from "@/components/page-filters-section";

const ELIXIR_TYPES = ['consumable'];
const INGREDIENTS_TYPES = ['ingredient', 'ingredient-land', 'ingredient-wizard'];

export default {
    components: {PageFiltersSection},
    data() {
        return {
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Description', value: 'description'},
                {text: 'Marketplace', value: 'marketplace'},
            ],
            search: '',
            enhancementsList: [],
        };
    },
    mounted: function () {
        this.getAllEnhancements();
    },
    computed: {
        elixirsList() {
            return this.enhancementsList.filter((row) => ELIXIR_TYPES.includes(row.type));
        },
        ingredientsList() {
            return this.enhancementsList.filter((row) => INGREDIENTS_TYPES.includes(row.type));
        }
    },
    methods: {
        init() {
        },
        getAllEnhancements() {
            const ids = EnhancementContract.getAllEnhancementsIds();
            const url = process.env.VUE_APP_ENHANCEMENT_NFT_SERVER_URL + ids;
            apiServiceVue.get(url).then(res => {
                this.enhancementsList = Object.values(res.data);
            })
                .catch((e) => {
                    console.error(e);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Cannot fetch data'
                    });
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.v-application .page-content {
    margin-top: 130px;
}

::v-deep .top-section {
    height: 160px;
}

.page-content {
    margin-bottom: 50px;
}

.table-img {
    width: 48px;
    height: 48px;
    margin: 3px 10px 3px 0;
}

@media only screen and (max-width: $sm) {
}
</style>

<style lang="scss">
.v-data-table > .v-data-table__wrapper .v-data-table__mobile-table-row {
    margin: 10px 5px; // margin between each row
    border: 1px solid #ededed;
    display: block; // display table row as block
}
</style>
