var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "page-filters-section",
        { attrs: { "top-img": "/img/enhancement/enhancement-header.png" } },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c("h1", [_vm._v("Wizarropedia")]),
              _c("h4", [_vm._v("Enhancements list")]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "page-content" },
        [
          _c(
            "v-card",
            { staticClass: "transparent", attrs: { dark: "" } },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" Elixirs "),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "transparent",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.elixirsList,
                  loading: 0 === _vm.elixirsList.length,
                  "loader-height": "10",
                  "items-per-page": 200,
                  "hide-default-footer": true,
                  sortable: true,
                  search: _vm.search,
                  "mobile-breakpoint": "501",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center justify-start" },
                          [
                            _c("img", {
                              staticClass: "table-img",
                              attrs: { src: item.thumbnail_82, alt: item.name },
                            }),
                            _c("span", [_vm._v(_vm._s(item.name))]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.marketplace",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: {
                                name: "Marketplace",
                                params: { search: item.name },
                              },
                            },
                          },
                          [_vm._v(" Buy now ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c(
            "v-card",
            { staticClass: "transparent", attrs: { dark: "" } },
            [
              _c(
                "v-card-title",
                [
                  _vm._v(" Summoning ingredients "),
                  _c("v-spacer"),
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "mdi-magnify",
                      label: "Search",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "transparent",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.ingredientsList,
                  loading: 0 === _vm.ingredientsList.length,
                  "items-per-page": 200,
                  "hide-default-footer": true,
                  sortable: true,
                  search: _vm.search,
                  "mobile-breakpoint": "501",
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.name",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "d-flex align-center justify-start" },
                          [
                            _c("img", {
                              staticClass: "table-img",
                              attrs: { src: item.thumbnail_82, alt: item.name },
                            }),
                            _c("span", [_vm._v(_vm._s(item.name))]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.marketplace",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              to: {
                                name: "Marketplace",
                                params: { search: item.name },
                              },
                            },
                          },
                          [_vm._v(" Buy now ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }